import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj4 = () => {
  return (
    <Layout>
      <SEO
        title="Page Rank-How does google work"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>
      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-4">
          <div className="py-1-heading">
            <h1>Page Rank-How does google work</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Page%20Rank-How%20Does%20Google%20Work"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              PageRank (PR) is an algorithm used by Google Search to rank
              websites in their search engine results.
              <br />
              In our case, it is the algorithm to rank the nodes in a given
              graph.
              <br />
              <br />
              What is idea behind it?
              <br /> In short, first taking random walk on the network.
              <br />
              Then, increment the points of each node.
              <br />
              then find the node with the highest points.
              <br />
              Simple !!!
              <br />
              <br />
              Here, on the top notch, nodes represent the websites <br />
              <br />
              We have two methods for this...
              <br /> 1. Random Walking Method:- Crawlers take random walk on
              www(world wide web) network and rank the website.
              <br />
              Again in our case, we take randomly a node and finds a (edge)
              outlink (in reality a hyperlink) and performs a random walk.
              <br />
              <br />
              2. Points Distribution Method:- Here the concept start from the
              'Directed graph' having directed edges.
              <br />
              Real world example:- Email Communication System
              <br />
              <br />
              That's the idea how ranking works over the web...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Networkx</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Matplotlib</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Operator</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj4
